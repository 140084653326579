<template>
    <div class="footer_box">
        <el-row type="flex" justify="space-around" class="row_box" style="">
            <el-col :span="8" class="left_box">
                <div class="left_menu">
                    <div class="menu_item" @click="routerTo('/home')">
                        {{ $t("navbar.home") }}
                    </div>
                    <div class="menu_item" @click="routerTo('/product')">
                        {{ $t("navbar.product") }}
                    </div>
                    <div class="menu_item" @click="routerTo('/case')">
                        {{ $t("navbar.case") }}
                    </div>
                    <div class="menu_item" @click="routerTo('/about/index')">
                        {{ $t("navbar.about") }}
                    </div>
                    <div
                        class="menu_item"
                        @click="routerTo('/contact/talentRecruitment')"
                    >
                        {{ $t("navbar.contact") }}
                    </div>
                </div>
                <div class="phone_title">
                    <i
                        class="el-icon-phone-outline"
                        style="padding-right: 10px; font-size: 20px"
                    ></i>
                    <span>{{ $t("footer.phoneTitle") }}</span>
                </div>
                <div class="phone_num">
                    <span>400-000-1391</span>
                    <span
                        style="
                            position: absolute;
                            left: 60%;
                            white-space: nowrap;
                        "
                        >021-55698408</span
                    >
                </div>
                <div class="phone_time">
                    {{ $t("footer.workTime") }}： 9:30-18:00
                </div>
                <div class="phone_time">{{ $t("footer.service") }}</div>
            </el-col>
            <el-col :span="14">
                <el-row type="flex" justify="space-around">
                    <el-col :span="18" class="info_box">
                        <div class="info_item">
                            <div>
                                <span style="padding-right: 20px"
                                    >{{ $t("footer.address") }}：{{
                                        $t("footer.addressUrl")
                                    }}</span
                                >
                                <span
                                    >{{
                                        $t("footer.phone")
                                    }}：021-55698408</span
                                >
                            </div>
                            <div>
                                {{
                                    $t("footer.email")
                                }}：info@pan-intelligence.com
                            </div>
                        </div>
                        <div class="info_item">
                            <div style="color: #acacac">
                                备案号：
                                <a
                                    style="
                                        color: #acacac;
                                        text-decoration: none;
                                    "
                                    href="https://beian.miit.gov.cn/"
                                    target="_blank"
                                >
                                    沪ICP备19041437号-7
                                </a>
                            </div>
                            <!-- <div style="color: #acacac">
                                {{ $t("footer.copyright") }}
                            </div> -->
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="code_box">
                            <div class="qr_code"></div>
                            <div class="code_text">
                                {{ $t("footer.Wechat") }}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "footerCom",
    methods: {
        routerTo(value) {
            this.$emit("menuChange", value);
            localStorage.setItem("active", value);
            this.$router.push(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.footer_box {
    // height: 260px;
    background: #404040;
    color: #fff;
    padding: 20px 0px;
    font-family: PingFang SC;

    .row_box {
        width: 82%;
        margin: 0 auto;
        align-items: center;
        height: 100%;

        .left_box {
            .left_menu {
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                .menu_item {
                    cursor: pointer;
                }
            }
            .phone_title {
                font-size: 16px;
                font-weight: 500;
            }
            .phone_num {
                font-size: 38px;
                font-weight: bold;
                position: relative;
            }
            .phone_time {
                font-size: 14px;
                font-weight: 500;
                color: #acacac;
                line-height: 26px;
            }
        }

        .info_box {
            height: 200px;
            text-align: right;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .info_item {
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                line-height: 26px;
            }
        }

        .code_box {
            width: 160px;
            text-align: center;
            padding: 10px;
            margin: 10px;
            background-color: #eeeeee30;
            border-radius: 5px;
            .qr_code {
                width: 140px;
                height: 140px;
                margin: 0 auto;
                background-image: url("@/assets/qrCode.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .code_text {
                line-height: 25px;
                font-size: 13px;
            }
        }
    }
}
</style>
